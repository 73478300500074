import { ReactNode } from 'react';
import styled from 'styled-components';

interface ModalSectionProps extends React.ComponentPropsWithoutRef<'div'> {
  title?: string;
  marginBottomTitle?: number;
  children: ReactNode;
}

const ModalSection = ({
  title,
  children,
  marginBottomTitle,
  ...rest
}: ModalSectionProps) => (
  <Wrapper {...rest}>
    {title && <Title $marginBottom={marginBottomTitle}>{title}</Title>}
    {children}
  </Wrapper>
);

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 27px 18px 20px;
  border-bottom: 1px solid #e0e0e0;
`;

interface TitleProps {
  $marginBottom?: number;
}

const Title = styled.div<TitleProps>`
  margin-bottom: ${({ $marginBottom }) =>
    $marginBottom ? $marginBottom + 'px' : '10px'};
  text-transform: uppercase;
  letter-spacing: 0.83px;
  font-size: 12px;
`;

export default ModalSection;
