/* eslint-disable @typescript-eslint/no-unused-vars */
import { HelpOutline } from '@mui/icons-material';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  CssBaseline,
  Divider,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Theme,
  Tooltip,
} from '@mui/material/';
import { makeStyles, withStyles } from '@mui/styles';
import clsx from 'clsx';
import { useCallback, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import colors from 'theme/patterns/colors';

import ModelIcon from 'components/ModelIcon';
import { OrganizationDataType } from 'components/SelectOrganization/SelectOrganizationReducer';
import { getOrganizationsData } from 'components/SelectOrganization/SelectOrganizationSelector';
import AppConfig from 'config/AppConfig';
import { getAccessToken, setNewTabOpeningFlag } from 'helpers/localeStorage';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import useSingleAndDoubleClicks from 'hooks/useSingleAndDoubleClicks';
import {
  closeDrawer,
  openDrawer,
  setStackModalData,
} from 'modules/GlobalActions';
import { ModalAction } from 'modules/GlobalReducer';
import { getConfiguration, getDrawerData } from 'modules/GlobalSelectors';
import { fetchUserInfo, logout } from 'modules/auth/AuthActions';
import { getUserInfo } from 'modules/auth/AuthSelectors';
import styled from 'styled-components';
import { ModelLabel, ModelName } from 'utils/enum';
import { isActivePage, openNewTab } from 'utils/utils';
import { LogoFullIcon, LogoFullIconStage } from '../Icons';
import { TOP_NAVBAR_HEIGHT } from '../TopNavbar/TopNavbar';
import AddButton from './AddButton';
import SwitchButton from './SwitchButton';
import ConfiguredItem from './ConfiguredItem';

export const openedDrawerWidth = 260;
export const closedDrawerWidth = 61;
const isProduction = AppConfig.appEnv === 'production';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: openedDrawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: openedDrawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflow: 'hidden',
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflow: 'hidden',
    width: `${closedDrawerWidth}px !important`,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  topDividerRoot: {
    height: 2,
    width: openedDrawerWidth - 1,
    position: 'absolute',
    zIndex: 1000,
    background:
      'linear-gradient(270deg, #ff9800 0%, #ff8733 50.36%, #cc3333 100%)',
  },
}));

interface CustomDrawerProps {
  children: React.ReactNode;
}

const CustomDrawer = ({ children }: CustomDrawerProps) => {
  const dispatch = useAppDispatch();
  const { activeOrganization }: OrganizationDataType =
    useAppSelector(getOrganizationsData);

  const classes = useStyles();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { state: open } = useAppSelector(getDrawerData);
  const { code, first, last } = useAppSelector(getUserInfo);
  const access_token = useAppSelector(getAccessToken);
  const isOrganizationActive = !!activeOrganization;

  const handleDrawerOpen = () => {
    dispatch(openDrawer());
  };

  const handleDrawerClose = () => {
    dispatch(closeDrawer());
  };

  const handleClickAuthMenu = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseAuthMenu = () => {
    setAnchorEl(null);
  };
  const handleClickLogout = () => {
    dispatch(logout(access_token));
    setAnchorEl(null);
  };
  const handleClickChangePassword = () => {
    setAnchorEl(null);
    return navigate('/auth/change-password');
  };
  const handleAddClick = (
    modelName: ModelName,
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    const isNewTab = e.ctrlKey || e.metaKey;

    if (isNewTab) {
      setNewTabOpeningFlag();
      openNewTab(
        activeOrganization
          ? `/${activeOrganization.namespace}/${ModalAction.Create}/${modelName}`
          : `/${ModalAction.Create}/${modelName}`,
      );
      return;
    }
    if (modelName) {
      dispatch(
        setStackModalData({
          modelName,
          modelIds: [],
          currentAction: ModalAction.Create,
        }),
      );
    }
  };

  const handleLogoClick = useCallback(
    (needOpenNewTab: boolean) => {
      if (needOpenNewTab) setNewTabOpeningFlag();
      dispatch(
        fetchUserInfo(
          0,
          needOpenNewTab ? openNewTab : navigate,
          activeOrganization?.namespace,
        ),
      );
    },
    [activeOrganization?.namespace, dispatch, navigate],
  );

  return (
    <DrawerWrapper>
      <CssBaseline />
      <SwitchButton
        open={open}
        handleDrawerClose={handleDrawerClose}
        handleDrawerOpen={handleDrawerOpen}
      />
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <Divider
          classes={{
            root: classes.topDividerRoot,
          }}
        />
        <DrawerList>
          <TopItems>
            <ListItemCustom
              isOrganizationActive={isOrganizationActive}
              logoButton
              handleCustomOnClick={handleLogoClick}
              style={{
                marginBottom: 8,
                cursor: 'pointer',
                backgroundColor: 'inherit',
              }}
            >
              <ListItemIcon>
                {isProduction ? <LogoFullIcon /> : <LogoFullIconStage />}
              </ListItemIcon>
            </ListItemCustom>

            <ListItemCustom
              isOrganizationActive={isOrganizationActive}
              colorDef="#000"
              modelName={ModelName.Program}
              pathname={pathname}
              handleAddClick={handleAddClick}
            >
              <CustomTooltip
                title={open ? '' : ModelLabel.DataFiles}
                placement="right"
                arrow
              >
                <ListItemIcon>
                  <ModelIcon modelName={ModelName.Program} />
                </ListItemIcon>
              </CustomTooltip>

              <ListItemTextCustom primary={ModelLabel.DataFiles} />
              <AddButton
                onClick={(e) => handleAddClick(ModelName.Program, e)}
              />
            </ListItemCustom>

            <ListItemCustom
              modelName={ModelName.Cell}
              pathname={pathname}
              isOrganizationActive={isOrganizationActive}
            >
              <CustomTooltip
                title={open ? '' : ModelLabel.Cell}
                placement="right"
                arrow
              >
                <ListItemIcon>
                  <ModelIcon
                    modelName={ModelName.Cell}
                    color={
                      isActivePage(
                        pathname,
                        ModelName.Cell,
                        isOrganizationActive,
                      )
                        ? 'white'
                        : 'black'
                    }
                  />
                </ListItemIcon>
              </CustomTooltip>
              <ListItemTextCustom primary={ModelLabel.Cell} />
              <AddButton onClick={(e) => handleAddClick(ModelName.Cell, e)} />
            </ListItemCustom>

            <Divider style={{ margin: '17px 0' }} />

            <ListItemCustom
              isOrganizationActive={isOrganizationActive}
              colorDef="#000"
              modelName={ModelName.Experiment}
              pathname={pathname}
              handleAddClick={handleAddClick}
            >
              <CustomTooltip
                title={open ? '' : ModelLabel.Experiments}
                placement="right"
                arrow
              >
                <ListItemIcon>
                  <ModelIcon modelName={ModelName.Experiment} />
                </ListItemIcon>
              </CustomTooltip>

              <ListItemTextCustom primary={ModelLabel.Experiments} />
              <AddButton
                onClick={(e) => handleAddClick(ModelName.Experiment, e)}
              />
            </ListItemCustom>

            <ListItemCustom
              modelName={ModelName.Material}
              pathname={pathname}
              isOrganizationActive={isOrganizationActive}
            >
              <CustomTooltip
                title={open ? '' : ModelLabel.Materials}
                placement="right"
                arrow
              >
                <ListItemIcon>
                  <ModelIcon modelName={ModelName.Material} />
                </ListItemIcon>
              </CustomTooltip>
              <ListItemTextCustom primary={ModelLabel.Materials} />
              <AddButton
                onClick={(e) => handleAddClick(ModelName.Material, e)}
              />
            </ListItemCustom>

            <ListItemCustom
              modelName={ModelName.Process}
              pathname={pathname}
              isOrganizationActive={isOrganizationActive}
            >
              <CustomTooltip
                title={open ? '' : ModelLabel.Processes}
                placement="right"
                arrow
              >
                <ListItemIcon>
                  <ModelIcon modelName={ModelName.Process} />
                </ListItemIcon>
              </CustomTooltip>

              <ListItemTextCustom primary={ModelLabel.Processes} />
              <AddButton
                onClick={(e) => handleAddClick(ModelName.Process, e)}
              />
            </ListItemCustom>

            <ListItemCustom
              isOrganizationActive={isOrganizationActive}
              modelName={ModelName.Measurement}
              pathname={pathname}
            >
              <CustomTooltip
                title={open ? '' : ModelLabel.Measurements}
                placement="right"
                arrow
              >
                <ListItemIcon>
                  <ModelIcon modelName={ModelName.Measurement} />
                </ListItemIcon>
              </CustomTooltip>

              <ListItemTextCustom primary={ModelLabel.Measurements} />
              <AddButton
                onClick={(e) => handleAddClick(ModelName.Measurement, e)}
              />
            </ListItemCustom>

            <Divider style={{ margin: '17px 0' }} />

            <ListItemCustom
              isOrganizationActive={isOrganizationActive}
              modelName={ModelName.MaterialType}
              pathname={pathname}
              handleAddClick={handleAddClick}
            >
              <CustomTooltip
                title={open ? '' : ModelLabel.MaterialTypes}
                placement="right"
                arrow
              >
                <ListItemIcon>
                  <ModelIcon
                    modelName={ModelName.MaterialType}
                    color={
                      isActivePage(
                        pathname,
                        ModelName.MaterialType,
                        isOrganizationActive,
                      )
                        ? 'white'
                        : 'black'
                    }
                  />
                </ListItemIcon>
              </CustomTooltip>

              <ListItemTextCustom primary={ModelLabel.MaterialTypes} />
              <AddButton
                onClick={(e) => handleAddClick(ModelName.MaterialType, e)}
              />
            </ListItemCustom>

            <ListItemCustom
              isOrganizationActive={isOrganizationActive}
              modelName={ModelName.ProcessType}
              pathname={pathname}
              handleAddClick={handleAddClick}
            >
              <CustomTooltip
                title={open ? '' : ModelLabel.ProcessTypes}
                placement="right"
                arrow
              >
                <ListItemIcon>
                  <ModelIcon
                    modelName={ModelName.ProcessType}
                    color={
                      isActivePage(
                        pathname,
                        ModelName.ProcessType,
                        isOrganizationActive,
                      )
                        ? 'white'
                        : 'black'
                    }
                  />
                </ListItemIcon>
              </CustomTooltip>

              <ListItemTextCustom primary={ModelLabel.ProcessTypes} />
              <AddButton
                onClick={(e) => handleAddClick(ModelName.ProcessType, e)}
              />
            </ListItemCustom>

            <ListItemCustom
              isOrganizationActive={isOrganizationActive}
              modelName={ModelName.MeasurementType}
              pathname={pathname}
              handleAddClick={handleAddClick}
            >
              <CustomTooltip
                title={open ? '' : ModelLabel.MeasurementTypes}
                placement="right"
                arrow
              >
                <ListItemIcon>
                  <ModelIcon
                    modelName={ModelName.MeasurementType}
                    color={
                      isActivePage(
                        pathname,
                        ModelName.MeasurementType,
                        isOrganizationActive,
                      )
                        ? 'white'
                        : 'black'
                    }
                  />
                </ListItemIcon>
              </CustomTooltip>

              <ListItemTextCustom primary={ModelLabel.MeasurementTypes} />
              <AddButton
                onClick={(e) => handleAddClick(ModelName.MeasurementType, e)}
              />
            </ListItemCustom>

            <ListItemCustom
              isOrganizationActive={isOrganizationActive}
              modelName={ModelName.ControlType}
              pathname={pathname}
              handleAddClick={handleAddClick}
            >
              <CustomTooltip
                title={open ? '' : ModelLabel.ControlTypes}
                placement="right"
                arrow
              >
                <ListItemIcon>
                  <ModelIcon
                    modelName={ModelName.ControlType}
                    color={
                      isActivePage(
                        pathname,
                        ModelName.ControlType,
                        isOrganizationActive,
                      )
                        ? 'white'
                        : 'black'
                    }
                  />
                </ListItemIcon>
              </CustomTooltip>

              <ListItemTextCustom primary={ModelLabel.ControlTypes} />
              <AddButton
                onClick={(e) => handleAddClick(ModelName.ControlType, e)}
              />
            </ListItemCustom>

            <Divider style={{ margin: '17px 0' }} />

            <ListItemCustom
              isOrganizationActive={isOrganizationActive}
              colorDef="#000"
              modelName={ModelName.User}
              pathname={pathname}
            >
              <CustomTooltip
                title={open ? '' : ModelLabel.Users}
                placement="right"
                arrow
              >
                <ListItemIcon>
                  <ModelIcon modelName={ModelName.User} />
                </ListItemIcon>
              </CustomTooltip>

              <ListItemTextCustom primary={ModelLabel.Users} />
            </ListItemCustom>

            <ListItemCustom
              isOrganizationActive={isOrganizationActive}
              colorDef="#000"
              modelName={ModelName.Team}
              pathname={pathname}
              handleAddClick={handleAddClick}
            >
              <CustomTooltip
                title={open ? '' : ModelLabel.Teams}
                placement="right"
                arrow
              >
                <ListItemIcon>
                  <ModelIcon modelName={ModelName.Team} />
                </ListItemIcon>
              </CustomTooltip>

              <ListItemTextCustom primary={ModelLabel.Teams} />
              <AddButton onClick={(e) => handleAddClick(ModelName.Team, e)} />
            </ListItemCustom>

            <ListItemCustom
              isOrganizationActive={isOrganizationActive}
              colorDef="#000"
              modelName={ModelName.Site}
              pathname={pathname}
              handleAddClick={handleAddClick}
            >
              <CustomTooltip
                title={open ? '' : ModelLabel.Sites}
                placement="right"
                arrow
              >
                <ListItemIcon>
                  <ModelIcon modelName={ModelName.Site} />
                </ListItemIcon>
              </CustomTooltip>

              <ListItemTextCustom primary={ModelLabel.Sites} />
              <AddButton onClick={(e) => handleAddClick(ModelName.Site, e)} />
            </ListItemCustom>

            <ListItemCustom
              isOrganizationActive={isOrganizationActive}
              modelName={ModelName.Instrument}
              pathname={pathname}
              handleAddClick={handleAddClick}
            >
              <CustomTooltip
                title={open ? '' : ModelLabel.Instruments}
                placement="right"
                arrow
              >
                <ListItemIcon>
                  <ModelIcon
                    modelName={ModelName.Instrument}
                    color={
                      isActivePage(
                        pathname,
                        ModelName.Instrument,
                        isOrganizationActive,
                      )
                        ? 'white'
                        : 'black'
                    }
                  />
                </ListItemIcon>
              </CustomTooltip>

              <ListItemTextCustom primary={ModelLabel.Instruments} />
              <AddButton
                onClick={(e) => handleAddClick(ModelName.Instrument, e)}
              />
            </ListItemCustom>
          </TopItems>

          <BottomItems>
            <ConfiguredItem
              conditionField="showOrgSettings"
              isToSatisfy={false}
            >
              <ListItemCustom
                colorDef="#000"
                modelName="organization-settings"
                pathname={pathname}
                style={{ marginTop: 8 }}
                isOrganizationActive={isOrganizationActive}
              >
                <CustomTooltip
                  title={open ? '' : ModelLabel.Organization}
                  placement="right"
                  arrow
                >
                  <ListItemIcon style={{ marginRight: 21, paddingLeft: 10 }}>
                    <SettingsIcon />
                  </ListItemIcon>
                </CustomTooltip>
                <ListItemTextCustom primary={ModelLabel.Organization} />
              </ListItemCustom>
            </ConfiguredItem>

            <ListItemCustom
              colorDef="#000"
              modelName="help"
              pathname={pathname}
              style={{ marginTop: 8 }}
              isOrganizationActive={isOrganizationActive}
            >
              <CustomTooltip
                title={open ? '' : ModelLabel.Help}
                placement="right"
                arrow
              >
                <ListItemIcon style={{ marginRight: 21, paddingLeft: 10 }}>
                  <HelpOutline />
                </ListItemIcon>
              </CustomTooltip>

              <ListItemTextCustom primary={ModelLabel.Help} />
            </ListItemCustom>

            <ListItemButton style={{ paddingLeft: 10 }}>
              <ListItemIcon style={{ cursor: 'pointer' }}>
                <RoundBottomItem onClick={handleClickAuthMenu}>
                  {code}
                </RoundBottomItem>
              </ListItemIcon>
              <Menu
                id="auth-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseAuthMenu}
              >
                <MenuItem onClick={handleClickChangePassword}>
                  Change Password
                </MenuItem>
                <MenuItem onClick={handleClickLogout}>Logout</MenuItem>
              </Menu>
              <ListItemTextCustom style={{ margin: 0, padding: '0 0 0 5px' }}>
                <span style={{ fontSize: 14 }}>
                  {first} {last}
                </span>
                <br />
                <span style={{ fontSize: 14, color: 'rgba(0,0,0,0.54)' }}>
                  {code}
                </span>
              </ListItemTextCustom>
            </ListItemButton>
          </BottomItems>
        </DrawerList>
      </Drawer>
      <PageContent>{children}</PageContent>
    </DrawerWrapper>
  );
};
const CustomTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: colors.black,
    fontSize: '14px',
    padding: '6px 8px',
    borderRadius: '4px',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
  },
  arrow: {
    color: '#f2f2f2',
  },
}))(Tooltip);

const DrawerWrapper = styled.div`
  display: flex;
  height: 100%;
  position: relative;

  & .MuiDrawer-paperAnchorDockedLeft {
    position: fixed;
    top: 30px;
  }
`;

const DrawerList = styled(List)`
  height: calc(100% - ${TOP_NAVBAR_HEIGHT}px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 0 !important;
  background-color: ${isProduction ? colors.white : colors.grey};
`;

const ListItemCustom = styled(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({
    modelName,
    handleAddClick,
    pathname,
    logoButton,
    colorDef,
    isOrganizationActive,
    handleCustomOnClick,
    ...rest
  }: any) => {
    const { data } = useAppSelector(getConfiguration);
    const navigate = useNavigate();
    const { activeOrganization }: OrganizationDataType =
      useAppSelector(getOrganizationsData);

    const onClick = (e: React.MouseEvent) => {
      const isNewTab = e.metaKey || e.ctrlKey;
      if (handleCustomOnClick) {
        return handleCustomOnClick(isNewTab);
      }
      const path = activeOrganization
        ? `/${activeOrganization.namespace}/${modelName}`
        : `/${modelName}`;

      if (modelName === 'help') {
        openNewTab(AppConfig.supportUrl);
        return;
      }
      if (isNewTab) {
        setNewTabOpeningFlag();
        openNewTab(path);
        return;
      }
      navigate(path);
    };

    const onDoubleClick = (e: React.MouseEvent) => {
      const path = activeOrganization
        ? `/${activeOrganization.namespace}/${modelName}`
        : `/${modelName}`;
      (handleAddClick && handleAddClick(modelName, e)) || navigate(path);
    };

    const { handleClick, handleDoubleClick } = useSingleAndDoubleClicks({
      onClick: onClick,
      onDoubleClick: onDoubleClick,
    });

    return (
      <>
        {!data?.disabledPages?.includes(modelName) && (
          <ListItemButton
            to=""
            autoFocus={
              isActivePage(pathname, modelName, isOrganizationActive)
                ? true
                : false
            }
            component={Link}
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              e.persist();
              return handleClick(e);
            }}
            onDoubleClick={handleDoubleClick}
            {...rest}
          />
        )}
      </>
    );
  },
)`
  padding: ${({ logoButton }) => (logoButton ? '2px' : '0')} !important;
  height: ${({ logoButton }) => (logoButton ? '48px' : '36px')};

  .MuiSvgIcon-root {
    font-size: 1.5rem;
  }

  .MuiListItemIcon-root {
    height: 28px;
    min-width: 50px;
    justify-content: center;
    margin-right: 21px;
    padding-left: 9px;
    align-items: center;
    border-radius: 0 4px 4px 0;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: ${({
      modelName,
      pathname,
      isOrganizationActive,
      theme: { colors },
    }: any) => {
      const isActive = isActivePage(pathname, modelName, isOrganizationActive);
      const activeColor = colors[modelName]?.color || colors.primary;
      return isActive ? activeColor : isProduction ? colors.white : colors.grey;
    }};
    color: ${({
      modelName,
      colorDef,
      pathname,
      isOrganizationActive,
      theme: { colors },
    }: any) => {
      const isActive = isActivePage(pathname, modelName, isOrganizationActive);
      const curColor = colors[modelName]?.color || colors.primary;
      return isActive
        ? '#fff !important'
        : `${colorDef || curColor} !important`;
    }};

    .MuiSvgIcon-root {
      color: ${({
        modelName,
        colorDef,
        pathname,
        isOrganizationActive,
        theme: { colors },
      }: any) => {
        const isActive = isActivePage(
          pathname,
          modelName,
          isOrganizationActive,
        );
        const curColor = colors[modelName]?.color || colors.primary;
        return isActive
          ? '#fff !important'
          : `${colorDef || curColor} !important`;
      }};
    }
  }

  .add-button {
    margin-top: 5px;
    visibility: hidden;
    opacity: 0;
    transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  &:hover {
    .MuiListItemIcon-root {
      background-color: ${({
        pathname,
        modelName,
        isOrganizationActive,
      }: any) => {
        const isActive = isActivePage(
          pathname,
          modelName,
          isOrganizationActive,
        );
        return isActive || !isProduction ? '' : '#f5f5f5';
      }};
    }

    .add-button {
      visibility: visible;
      opacity: 1;
    }
  }
`;

const ListItemTextCustom = styled(ListItemText)`
  padding: 8px 16px 8px 0;

  .MuiListItemText-primary {
    font-size: 14px;
  }
`;

const RoundBottomItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  min-width: 40px;
  background-color: ${({ theme: { colors } }) =>
    isProduction ? colors.grey : colors.white};
  border-radius: 50%;
  text-transform: uppercase;
`;

const TopItems = styled.div`
  height: calc(100% - 110px);
  overflow: hidden auto;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 7px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #a8a8a8;
  }
`;

const BottomItems = styled.div`
  border-top: 1px solid rgb(0 0 0 / 12%);
`;

const PageContent = styled.main`
  width: 100%;
`;

export default CustomDrawer;
