import axiosInstance from 'config/AxiosConfig';
import { Dispatch } from 'redux';
import { API_ENDPOINTS } from 'utils/enum';
import * as actionTypes from './MatrixActionsTypes';
import { getFilesMatrixModelDef } from 'helpers/getFilesMatrixModelDef';
import axios from 'axios';

interface fetchMatrixTableProps {
  params?: Record<string, any>;
  shouldUpdateState?: boolean;
}

interface fetchMatrixOrientingTableProps {
  params?: Record<string, any>;
  modelApi: string;
}

export const fetchMatrixTable =
  ({ params = {}, shouldUpdateState = true }: fetchMatrixTableProps) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: actionTypes.FETCH_MATRIX_START });
    try {
      const { data } = await axiosInstance.post(
        `/${API_ENDPOINTS.matrix.read}`,
        params,
      );

      if (data?.error) {
        return dispatch({
          type: actionTypes.FETCH_MATRIX_ERROR,
          payload: data?.error,
        });
      }

      const fileMatrixModelDef = getFilesMatrixModelDef(
        data?.rows?.at(0) || {},
      );
      const isToUpdateModelDef = fileMatrixModelDef.length > 0;

      if (isToUpdateModelDef) {
        dispatch({
          type: actionTypes.MATRIX_ADD_MODEL_DEFINITIONS,
          payload: fileMatrixModelDef,
        });
      }

      data.lastRow = data?.rows?.length > 0 ? data?.lastRow + 1 : 0;

      data.shouldUpdateState = shouldUpdateState;

      return dispatch({
        type: actionTypes.FETCH_MATRIX_SUCCESS,
        payload: data,
      });
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const errorMessage = (err.response?.data as { error: string }).error;

        dispatch({
          type: actionTypes.FETCH_MATRIX_ERROR,
          payload: errorMessage,
        });
      } else {
        dispatch({
          type: actionTypes.FETCH_MATRIX_ERROR,
          payload: err.message,
        });
        console.log(err.message);
      }
    }
  };

export const fetchMatrixOrientingTable =
  ({ params = {}, modelApi }: fetchMatrixOrientingTableProps) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: actionTypes.FETCH_MATRIX_ORIENTING_TABLE_START });
    try {
      const { data } = await axiosInstance.post(
        `/${API_ENDPOINTS.models.getTable}/${modelApi}`,
        params,
      );

      if (data.error) {
        return dispatch({
          type: actionTypes.FETCH_MATRIX_ORIENTING_TABLE_ERROR,
          payload: data.error,
        });
      }

      return dispatch({
        type: actionTypes.FETCH_MATRIX_ORIENTING_TABLE_SUCCESS,
        payload: data.rows,
      });
    } catch (err) {
      dispatch({
        type: actionTypes.FETCH_MATRIX_ORIENTING_TABLE_ERROR,
        payload: err.message,
      });
      console.log(err.message);
    }
  };

export const setMatrixTableFilter = (payload: Record<string, any>) => ({
  type: actionTypes.SET_MATRIX_TABLE_FILTER,
  payload,
});

export const setMatrixTableSort = (payload: Record<string, any>) => ({
  type: actionTypes.SET_MATRIX_TABLE_SORT,
  payload,
});
