import { Outlet, Route, Routes } from 'react-router-dom';
import Preloader from 'components/Preloader/Preloader';
import ConfiguredRoute from 'components/ConfiguredRoute';
import { lazy, Suspense } from 'react';

const AccountSettingsPage = lazy(
  () => import('./modules/landing/pages/AccountSettingsPage'),
);

const OrganizationSettingsPage = lazy(
  () => import('./modules/landing/pages/OrganizationSettingsPage'),
);

const ModalModelContainer = lazy(
  () => import('./containers/ModalModelContainer'),
);

const ModalModelContainerWrapper = lazy(
  () => import('./containers/ModalModelContainerWrapper'),
);

const LandingPage = lazy(() => import('./modules/landing/pages/LandingPage'));

const FeedbackPage = lazy(() => import('./modules/landing/pages/FeedbackPage'));

const VersionPage = lazy(() => import('./modules/landing/pages/VersionPage'));

const MaterialsPage = lazy(
  () => import('./modules/landing/pages/MaterialsPage'),
);

const ExperimentsPage = lazy(
  () => import('./modules/landing/pages/ExperimentsPage'),
);

const ExplorePage = lazy(() => import('./modules/landing/pages/ExplorePage'));

const RelatedPage = lazy(() => import('./modules/landing/pages/RelatedPage'));

const ProcessesPage = lazy(
  () => import('./modules/landing/pages/ProcessesPage'),
);

const MeasurementsPage = lazy(
  () => import('./modules/landing/pages/MeasurementsPage'),
);

const MaterialTypesPage = lazy(
  () => import('./modules/landing/pages/MaterialTypesPage'),
);

const ProcessTypesPage = lazy(
  () => import('./modules/landing/pages/ProcessTypesPage'),
);

const MeasurementTypesPage = lazy(
  () => import('./modules/landing/pages/MeasurementTypesPage'),
);

const ControlTypesPage = lazy(
  () => import('./modules/landing/pages/ControlTypesPage'),
);

const UsersPage = lazy(() => import('./modules/landing/pages/UsersPage'));

const TeamsPage = lazy(() => import('./modules/landing/pages/TeamsPage'));

const SitesPage = lazy(() => import('./modules/landing/pages/SitesPage'));

const InstrumentsPage = lazy(
  () => import('./modules/landing/pages/InstrumentsPage'),
);

const ApiDocsPage = lazy(() => import('./modules/landing/pages/ApiDocsPage'));

const DataFilesPage = lazy(
  () => import('./modules/landing/pages/DataFilesPage'),
);

const CancellationPage = lazy(
  () => import('./modules/landing/pages/CancellationPage'),
);

const ReactivatePage = lazy(
  () => import('./modules/landing/pages/ReactivatePage'),
);

const OriginalFile = lazy(
  () => import('./modules/landing/pages/OriginalFilePage'),
);

const NotFoundPage = lazy(() => import('./modules/landing/pages/NotFoundPage'));

const ChangePasswordPage = lazy(
  () => import('./modules/auth/pages/ChangePasswordPage'),
);
const ForgotPasswordPage = lazy(
  () => import('./modules/auth/pages/ForgotPasswordPage'),
);
const PasswordResetSentPage = lazy(
  () => import('./modules/auth/pages/PasswordResetSentPage'),
);
const UpdatePasswordPage = lazy(
  () => import('./modules/auth/pages/UpdatePasswordPage'),
);

const PreviewFilePage = lazy(() => import('./modules/previewFile'));

const CellsPage = lazy(() => import('./modules/landing/pages/CellsPage'));

const MatrixPage = lazy(() => import('./modules/landing/pages/MatrixPage'));

const HelpDeskPage = lazy(() => import('./modules/landing/pages/HelpDeskPage'));

const InvitePage = lazy(() => import('./modules/landing/pages/InvitePage'));

const RoutesWrapper: React.FC<{ basePath: string }> = ({ basePath }) => (
  <Routes>
    <Route
      path={basePath}
      element={
        <div>
          <Outlet />
        </div>
      }
    >
      <Route
        path=""
        element={
          <Suspense fallback={<Preloader />}>
            <LandingPage />
          </Suspense>
        }
      />
      <Route
        path="bfd/:model/:ids"
        element={
          <Suspense fallback={<Preloader />}>
            <ExplorePage />
          </Suspense>
        }
      />
      <Route
        path="docs"
        element={
          <Suspense fallback={<Preloader />}>
            <ApiDocsPage />
          </Suspense>
        }
      />
      <Route
        path="related/:source/:dest/:ids"
        element={
          <Suspense fallback={<Preloader />}>
            <RelatedPage />
          </Suspense>
        }
      />

      <Route
        path=":model/cancel/:ids"
        element={
          <Suspense fallback={<Preloader />}>
            <CancellationPage />
          </Suspense>
        }
      />
      <Route
        path=":model/uncancel/:ids"
        element={
          <Suspense fallback={<Preloader />}>
            <ReactivatePage />
          </Suspense>
        }
      />
      <Route
        path=":model/original-file/:ids"
        element={
          <Suspense fallback={<Preloader />}>
            <OriginalFile />
          </Suspense>
        }
      />

      <Route
        path=":action/:modelName"
        element={
          <Suspense fallback={<Preloader />}>
            <ModalModelContainerWrapper />
          </Suspense>
        }
      >
        <Route
          path=":id"
          element={
            <Suspense fallback={<Preloader />}>
              <ModalModelContainer />
            </Suspense>
          }
        />
      </Route>
      <Route
        path="matrix/:model/:db_ids"
        element={
          <Suspense fallback={<Preloader />}>
            <MatrixPage />
          </Suspense>
        }
      />

      <Route
        path="bfd/:action/:modelName"
        element={
          <Suspense fallback={<Preloader />}>
            <ModalModelContainer isFromBfd />
          </Suspense>
        }
      >
        <Route
          path=":id"
          element={
            <Suspense fallback={<Preloader />}>
              <ModalModelContainer />
            </Suspense>
          }
        />
      </Route>

      <Route
        path="account-settings"
        element={
          <Suspense fallback={<Preloader />}>
            <AccountSettingsPage />
          </Suspense>
        }
      />
      <Route
        path="organization-settings"
        element={
          <Suspense fallback={<Preloader />}>
            <ConfiguredRoute
              conditionField="showOrgSettings"
              isToSatisfy={false}
            >
              <OrganizationSettingsPage />
            </ConfiguredRoute>
          </Suspense>
        }
      />
      <Route path="helpdesk" element={<HelpDeskPage />} />

      <Route
        path="feedback/:id"
        element={
          <Suspense fallback={<Preloader />}>
            <FeedbackPage />
          </Suspense>
        }
      />
      <Route
        path="version"
        element={
          <Suspense fallback={<Preloader />}>
            <VersionPage />
          </Suspense>
        }
      />

      <Route
        path="material"
        element={
          <Suspense fallback={<Preloader />}>
            <MaterialsPage />
          </Suspense>
        }
      />
      <Route
        path="measurement"
        element={
          <Suspense fallback={<Preloader />}>
            <MeasurementsPage />
          </Suspense>
        }
      />
      <Route
        path="process"
        element={
          <Suspense fallback={<Preloader />}>
            <ProcessesPage />
          </Suspense>
        }
      />

      <Route
        path="experiment"
        element={
          <Suspense fallback={<Preloader />}>
            <ExperimentsPage />
          </Suspense>
        }
      />
      <Route
        path="controlType"
        element={
          <Suspense fallback={<Preloader />}>
            <ControlTypesPage />
          </Suspense>
        }
      />
      <Route
        path="user"
        element={
          <Suspense fallback={<Preloader />}>
            <UsersPage />
          </Suspense>
        }
      />
      <Route
        path="team"
        element={
          <Suspense fallback={<Preloader />}>
            <TeamsPage />
          </Suspense>
        }
      />
      <Route
        path="site"
        element={
          <Suspense fallback={<Preloader />}>
            <SitesPage />
          </Suspense>
        }
      />
      <Route
        path="instrument"
        element={
          <Suspense fallback={<Preloader />}>
            <InstrumentsPage />
          </Suspense>
        }
      />
      <Route
        path="measurementType"
        element={
          <Suspense fallback={<Preloader />}>
            <MeasurementTypesPage />
          </Suspense>
        }
      />
      <Route
        path="processType"
        element={
          <Suspense fallback={<Preloader />}>
            <ProcessTypesPage />
          </Suspense>
        }
      />
      <Route
        path="materialType"
        element={
          <Suspense fallback={<Preloader />}>
            <MaterialTypesPage />
          </Suspense>
        }
      />
      <Route
        path="cell"
        element={
          <Suspense fallback={<Preloader />}>
            <CellsPage />
          </Suspense>
        }
      />

      <Route
        path="program"
        element={
          <Suspense fallback={<Preloader />}>
            <DataFilesPage />
          </Suspense>
        }
      />

      <Route
        path="auth/change-password"
        element={
          <Suspense fallback={<Preloader />}>
            <ChangePasswordPage />
          </Suspense>
        }
      />
      <Route
        path="auth/forgot-password"
        element={
          <Suspense fallback={<Preloader />}>
            <ForgotPasswordPage />
          </Suspense>
        }
      />
      <Route
        path="auth/password-reset-sent"
        element={
          <Suspense fallback={<Preloader />}>
            <PasswordResetSentPage />
          </Suspense>
        }
      />
      <Route
        path="auth/confirmforgot"
        element={
          <Suspense fallback={<Preloader />}>
            <UpdatePasswordPage />
          </Suspense>
        }
      />
      <Route
        path="program/file"
        element={
          <Suspense fallback={<Preloader />}>
            <PreviewFilePage />
          </Suspense>
        }
      />

      <Route path="invite" element={<InvitePage />} />

      <Route
        path="*"
        element={
          <Suspense fallback={<Preloader />}>
            <NotFoundPage />
          </Suspense>
        }
      />
    </Route>
  </Routes>
);

export default RoutesWrapper;
