import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';

import { Modal } from 'components/Modal';
import { clearQueueModalState, setStackModalData } from 'modules/GlobalActions';
import { ModelName } from 'utils/enum';

import {
  clearBlockModalData,
  clearBlocksModalInfo,
} from 'modules/explore/ExploreActions';
import { getBlocksModalInfo } from 'modules/explore/ExploreSelectors';
import { ModalAction } from 'modules/GlobalReducer';
import { getQueueModalInfo } from 'modules/GlobalSelectors';
import { Suspense, useCallback, lazy } from 'react';
import styled from 'styled-components';

const Material = lazy(() => import('modules/materials/Material'));
const Process = lazy(() => import('modules/processes/Process'));

const ModalBlocksModelContainer = () => {
  const dispatch = useAppDispatch();
  const blocksModalInfo = useAppSelector(getBlocksModalInfo);
  const queueModalInfo = useAppSelector(getQueueModalInfo);

  const handleCloseModal = useCallback(() => {
    dispatch(clearBlocksModalInfo());
    dispatch(clearQueueModalState());
    dispatch(clearBlockModalData());
  }, [dispatch]);

  const handleClickInfoIcon = useCallback(
    (ids: number[], modelName: string) => {
      dispatch(
        setStackModalData({
          modelName,
          modelIds: ids,
          currentAction: ModalAction.View,
        }),
      );
    },
    [dispatch],
  );

  const showBlockModals = blocksModalInfo.showModals;
  const isSingleModal =
    blocksModalInfo.data.length === 1 && blocksModalInfo.data[0].length === 1;
  const showQueueModal = queueModalInfo.showModal;

  const blockModalsComponents = blocksModalInfo.data.map((m, index) => {
    const isLastModal = blocksModalInfo.data.length - 1 - index === 0;
    const isMaterialModal = m.every((x) => x.modelName === ModelName.Material);
    const isProcessModal = m.every((x) => x.modelName === ModelName.Process);

    if (isProcessModal) {
      return (
        <ModalBlockModelWrapper key={index} data-id="block-modal">
          <Suspense>
            <Process
              showBlockModals={true}
              blocksModalInfo={m}
              rootIndex={index}
              handleClickInfoIcon={handleClickInfoIcon}
              isLastModal={isLastModal}
              isSingleModal={isSingleModal}
              showQueueModal={showQueueModal}
              handleCloseModal={handleCloseModal}
            />
          </Suspense>
        </ModalBlockModelWrapper>
      );
    }
    if (isMaterialModal) {
      return (
        <ModalBlockModelWrapper key={index} data-id="block-modal">
          <Suspense>
            <Material
              showBlockModals={true}
              blocksModalInfo={m}
              rootIndex={index}
              handleClickInfoIcon={handleClickInfoIcon}
              isSingleModal={isSingleModal}
              showQueueModal={showQueueModal}
              isLastModal={isLastModal}
              handleCloseModal={handleCloseModal}
            />
          </Suspense>
        </ModalBlockModelWrapper>
      );
    }
  });

  let modalProps = {};
  modalProps = {
    ...modalProps,
    paperStyles: {
      backgroundColor: 'transparent',
      width: '100%',
      height: '100%',
    },
  };

  return (
    <>
      <Modal
        open={showBlockModals}
        handleClose={() => {
          handleCloseModal();
        }}
        {...modalProps}
      >
        <ModalBlocksModelWrapper justifyCenter={isSingleModal}>
          <ModalBlocksContainer>{blockModalsComponents}</ModalBlocksContainer>
        </ModalBlocksModelWrapper>
      </Modal>
    </>
  );
};

const ModalBlocksModelWrapper = styled.div<{ justifyCenter: boolean }>`
  display: flex;
  width: 100vw;
  height: 100%;
  overflow: auto;
  position: relative;
`;

const ModalBlocksContainer = styled.div`
  display: flex;
  width: fit-content;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

const ModalBlockModelWrapper = styled.div`
  background-color: white;
  margin: 0 40px;
  min-width: 380px;
  position: relative;
  max-height: 95vh;
`;

export default ModalBlocksModelContainer;
