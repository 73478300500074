import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';

import { Modal } from 'components/Modal';
import { OrganizationDataType } from 'components/SelectOrganization/SelectOrganizationReducer';
import { getOrganizationsData } from 'components/SelectOrganization/SelectOrganizationSelector';
import {
  clearAllStackModalData,
  clearCreatedStateModelItem,
  clearQueueModalState,
  clearStackModalData,
  setStackModalData,
} from 'modules/GlobalActions';
import { ModalAction } from 'modules/GlobalReducer';
import {
  getModelItem,
  getQueueModalInfo,
  getStackModalData,
} from 'modules/GlobalSelectors';
import { clearBlockModalData } from 'modules/explore/ExploreActions';
import { getBlockModalInfo } from 'modules/explore/ExploreSelectors';
import { lazy, useCallback, useEffect, useMemo, useRef, Suspense } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ModelName } from 'utils/enum';
import {
  capitalizedFirstLetter,
  getIdsArrayFromParams,
  refreshPage,
} from 'utils/utils';

const Material = lazy(() => import('modules/materials/Material'));
const Process = lazy(() => import('modules/processes/Process'));
const Measurement = lazy(() => import('modules/measurements/Measurement'));
const Experiment = lazy(() => import('modules/experiments/Experiment'));
const File = lazy(() => import('modules/files/File'));
const MaterialType = lazy(() => import('modules/materialTypes/MaterialType'));
const ProcessType = lazy(() => import('modules/processTypes/ProcessType'));
const MeasurementType = lazy(
  () => import('modules/measurementTypes/MeasurementType'),
);
const ControlType = lazy(() => import('modules/controlTypes/ControlType'));
const User = lazy(() => import('modules/users/User'));
const Team = lazy(() => import('modules/teams/Team'));
const Site = lazy(() => import('modules/sites/Site'));
const Instrument = lazy(() => import('modules/instruments/Instrument'));
const Cell = lazy(() => import('modules/cells/cellsModal'));

export type HandleCloseModalArgs = {
  resetStackInfo?: boolean;
  afterCreateItem?: boolean;
  isFromBfd?: boolean;
};

export type StackModalTypeProps = {
  isView: boolean;
  isEdit: boolean;
  isCreate: boolean;
  isMultipleView: boolean;
  isLoading: boolean;
  error: string;
  isReadyToRender: boolean;
  isLastStackModal: boolean;
  showEditQueueModal: boolean;
  currentModalInfo: {
    modelName: ModelName;
    currentModelIds: number[];
    modalData: Record<string, any>;
    action: ModalAction;
    isMultiple?: boolean;
    isFromBlockBFD?: boolean;
  };
  showQueueModal: boolean;
  handleCloseModal: (args?: HandleCloseModalArgs) => void;
  handleClickInfoIcon: (ids: number[], modelName: string) => void;
  handleClickEdit: () => void;
  updateStackModalData: () => void;
  updateStackModalDataAfterEdit: () => void;
};

export type ModalModelContainerProps = {
  isFromBfd?: boolean;
};

const modelsToCreateNewFile = [
  ModelName.Measurement,
  ModelName.Material,
  ModelName.Process,
  ModelName.Program,
  ModelName.Cell,
];

const modelsToAddPaperStyles = [
  ModelName.Material,
  ModelName.Measurement,
  ModelName.Process,
];

const getModalProps = (addPaperStyles: boolean, isCreateNewFile: boolean) => {
  const modalProps = {} as { [key: string]: any };
  if (addPaperStyles) modalProps.paperStyles = { width: 380 };
  if (isCreateNewFile) modalProps.contentWithScroll = false;
  return modalProps;
};

const ModalModelContainer: React.FC<ModalModelContainerProps> = ({
  isFromBfd,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname }: { pathname: string } = useLocation();
  const { activeOrganization }: OrganizationDataType =
    useAppSelector(getOrganizationsData);
  const {
    action: actionFromParams,
    id: idFromParams = '',
    modelName: modeNameFromParams,
  } = useParams<{
    action: ModalAction;
    id: string;
    modelName: ModelName;
  }>();

  const stackModalData = useAppSelector(getStackModalData);
  const queueModalInfo = useAppSelector(getQueueModalInfo);
  const blockModalData = useAppSelector(getBlockModalInfo);

  const { data: modelItemId, error: creationError } =
    useAppSelector(getModelItem);

  const idsFromParams = useMemo(
    () => getIdsArrayFromParams(idFromParams),
    [idFromParams],
  );
  const isModalOpenByQuery = !!(actionFromParams || idsFromParams.length);

  const {
    isLastStackModal,
    modalInfo,
    loading: isLoading,
    error,
    currentAction,
  } = stackModalData;

  const currentModelName =
    isModalOpenByQuery && isLastStackModal
      ? modeNameFromParams
      : stackModalData.modelName;

  const currentModalInfo = modalInfo[modalInfo.length - 1];

  const isView = currentAction === ModalAction.View;
  const isEdit = currentAction === ModalAction.Edit;
  const isCreate = currentAction === ModalAction.Create;
  const isMultipleView = currentModalInfo?.currentModelIds.length > 1;
  const isReadyToRender =
    !!currentModalInfo?.modelName &&
    currentModalInfo.modelName === currentModelName;

  const handleCloseModal = useCallback(
    (args?: HandleCloseModalArgs) => {
      if (queueModalInfo.showModal) {
        dispatch(clearQueueModalState());
      }

      if (
        isLastStackModal ||
        args?.resetStackInfo ||
        queueModalInfo.afterCreating
      ) {
        if (isModalOpenByQuery) {
          if (isFromBfd) {
            const path = activeOrganization
              ? `/${activeOrganization.namespace}/bfd/${modeNameFromParams}/${idFromParams}`
              : `/bfd/${modeNameFromParams}/${idFromParams}`;
            navigate(path);
          } else {
            const path = activeOrganization
              ? `/${activeOrganization.namespace}/${stackModalData.modelName}`
              : `/${stackModalData.modelName}`;
            navigate(path);
          }
        }
        (modelItemId ||
          args?.afterCreateItem ||
          currentModalInfo?.action === ModalAction.QueueEdit) &&
          refreshPage(pathname, stackModalData);
        dispatch(clearAllStackModalData());

        if (blockModalData.error || blockModalData.modelName) {
          dispatch(clearBlockModalData());
        }

        (creationError || modelItemId) &&
          dispatch(clearCreatedStateModelItem());
      } else {
        dispatch(clearStackModalData());
        (creationError || modelItemId) &&
          dispatch(clearCreatedStateModelItem());
      }
    },
    [
      queueModalInfo.showModal,
      queueModalInfo.afterCreating,
      stackModalData,
      dispatch,
      isModalOpenByQuery,
      modelItemId,
      currentModalInfo?.action,
      pathname,
      blockModalData.error,
      blockModalData.modelName,
      creationError,
      isFromBfd,
      activeOrganization,
      modeNameFromParams,
      idFromParams,
      navigate,
      isLastStackModal,
    ],
  );

  const handleClickInfoIcon = useCallback(
    (ids: number[], modelName: string) => {
      dispatch(
        setStackModalData({
          modelName,
          modelIds: ids,
          currentAction: ModalAction.View,
        }),
      );
    },
    [dispatch],
  );

  const handleClickEdit = useCallback(
    () =>
      currentModelName &&
      dispatch(
        setStackModalData({
          modelName: currentModelName,
          modelIds: currentModalInfo?.currentModelIds,
          currentAction: ModalAction.Edit,
        }),
      ),
    [currentModalInfo?.currentModelIds, dispatch, currentModelName],
  );

  const updateStackModalData = useCallback(() => {
    dispatch(clearStackModalData({ deep: isLastStackModal ? 1 : 2 }));
    if (queueModalInfo.showModal) {
      dispatch(clearQueueModalState());
    }
    currentModelName &&
      dispatch(
        setStackModalData({
          modelName: currentModelName,
          modelIds: currentModalInfo?.currentModelIds,
          currentAction: ModalAction.View,
        }),
      );
  }, [
    currentModalInfo?.currentModelIds,
    currentModelName,
    isLastStackModal,
    queueModalInfo.showModal,
    dispatch,
  ]);

  const updateStackModalDataAfterEdit = useCallback(() => {
    if (currentModalInfo?.action === ModalAction.QueueEdit) {
      refreshPage(pathname, stackModalData);
    }

    dispatch(clearStackModalData({ deep: 1 }));

    if (queueModalInfo.showModal) {
      dispatch(clearQueueModalState());
    }
  }, [
    queueModalInfo.showModal,
    currentModalInfo?.action,
    stackModalData,
    pathname,
    dispatch,
  ]);

  const showModal = capitalizedFirstLetter(currentModelName) in ModelName;

  const stackModalProps = useMemo(
    () => ({
      isView,
      isEdit,
      isCreate,
      error,
      currentModalInfo,
      isLoading,
      isMultipleView,
      isReadyToRender,
      isLastStackModal,
      showQueueModal: queueModalInfo.showModal,
      showEditQueueModal: currentAction === ModalAction.QueueEdit,
      handleCloseModal,
      handleClickInfoIcon,
      handleClickEdit,
      updateStackModalData,
      updateStackModalDataAfterEdit,
    }),
    [
      isView,
      isEdit,
      isCreate,
      error,
      currentModalInfo,
      isLoading,
      isMultipleView,
      isReadyToRender,
      isLastStackModal,
      currentAction,
      queueModalInfo.showModal,
      handleCloseModal,
      handleClickInfoIcon,
      handleClickEdit,
      updateStackModalData,
      updateStackModalDataAfterEdit,
    ],
  );

  const getCurrentModal = useCallback(() => {
    switch (currentModelName) {
      case ModelName.Material:
        return <Material {...stackModalProps} />;
      case ModelName.Process:
        return <Process {...stackModalProps} />;
      case ModelName.Measurement:
        return <Measurement {...stackModalProps} />;
      case ModelName.Program:
      case ModelName.Feedback:
        return <File {...stackModalProps} />;
      case ModelName.MaterialType:
        return <MaterialType {...stackModalProps} />;
      case ModelName.Experiment:
        return <Experiment {...stackModalProps} />;
      case ModelName.User:
        return <User {...stackModalProps} />;
      case ModelName.ProcessType:
        return <ProcessType {...stackModalProps} />;
      case ModelName.MeasurementType:
        return <MeasurementType {...stackModalProps} />;
      case ModelName.ControlType:
        return <ControlType {...stackModalProps} />;
      case ModelName.Team:
        return <Team {...stackModalProps} />;
      case ModelName.Site:
        return <Site {...stackModalProps} />;
      case ModelName.Instrument:
        return <Instrument {...stackModalProps} />;
      case ModelName.Cell:
        return <Cell {...stackModalProps} />;
      default:
        return null;
    }
  }, [currentModelName, stackModalProps]);

  const addPaperStyles = !!(
    currentModelName &&
    modelsToAddPaperStyles.includes(currentModelName) &&
    !isCreate
  );

  const isCreateNewFile =
    modelsToCreateNewFile.includes(currentModelName as ModelName) &&
    currentModalInfo?.action === ModalAction.Create;

  const modalProps = getModalProps(addPaperStyles, isCreateNewFile);

  const shouldFetchStackModalData = useRef<boolean>(true);

  useEffect(() => {
    if (isModalOpenByQuery && showModal) {
      if ((isEdit || isView) && !idsFromParams.length) {
        const path = activeOrganization
          ? `/${activeOrganization.namespace}/${currentModelName}`
          : `/${currentModelName}`;
        return navigate(path);
      }
      currentModelName &&
        shouldFetchStackModalData.current &&
        dispatch(
          setStackModalData({
            modelName: currentModelName,
            modelIds: isCreate ? [] : idsFromParams,
            currentAction: actionFromParams,
            isModalOpenByQuery: true,
          }),
        );
      shouldFetchStackModalData.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      open={stackModalData.showModal}
      handleClose={() => {
        if (queueModalInfo.showModal && !queueModalInfo.afterCreating) {
          return updateStackModalData();
        }
        return handleCloseModal();
      }}
      {...modalProps}
    >
      <ModalWrapper
        $autoHeight={
          queueModalInfo.showModal ||
          isCreateNewFile ||
          currentAction === ModalAction.QueueEdit
        }
        data-id="block-modal"
      >
        <Suspense>{getCurrentModal()}</Suspense>
      </ModalWrapper>
    </Modal>
  );
};

const ModalWrapper = styled.div<{ $autoHeight: boolean }>`
  height: ${({ $autoHeight }) => ($autoHeight ? 'inherit' : '95vh')};
  display: flex;
  flex-direction: column;
`;

export default ModalModelContainer;
