export const FETCH_MATRIX_START = 'FETCH_MATRIX_START';
export const FETCH_MATRIX_SUCCESS = 'FETCH_MATRIX_SUCCESS';
export const FETCH_MATRIX_ERROR = 'FETCH_MATRIX_ERROR';
export const SET_MATRIX_TABLE_FILTER = 'SET_MATRIX_TABLE_FILTER';
export const SET_MATRIX_TABLE_SORT = 'SET_MATRIX_TABLE_SORT';
export const SET_MATRIX_SHOW_CANCEL = 'SET_MATRIX_SHOW_CANCEL';
export const SET_MATRIX_SELECTED_NODES = 'SET_MATRIX_SELECTED_NODES';
export const CLEAR_MATRIX_TABLE = 'CLEAR_MATRIX_TABLE';

export const FETCH_MATRIX_ORIENTING_TABLE_START =
  'FETCH_MATRIX_ORIENTING_TABLE_START';
export const FETCH_MATRIX_ORIENTING_TABLE_SUCCESS =
  'FETCH_MATRIX_ORIENTING_TABLE_SUCCESS';
export const FETCH_MATRIX_ORIENTING_TABLE_ERROR =
  'FETCH_MATRIX_ORIENTING_TABLE_ERROR';

export const MATRIX_ADD_MODEL_DEFINITIONS = 'MATRIX_ADD_MODEL_DEFINITIONS';
