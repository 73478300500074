export const FETCH_ACTIVE_INCOMING_INVITATIONS_START =
  'FETCH_ACTIVE_INCOMING_INVITATIONS_START';
export const FETCH_ACTIVE_INCOMING_INVITATIONS_SUCCESS =
  'FETCH_ACTIVE_INCOMING_INVITATIONS_SUCCESS';
export const FETCH_ACTIVE_INCOMING_INVITATIONS_ERROR =
  'FETCH_ACTIVE_INCOMING_INVITATIONS_ERROR';

export const ACCOUNT_SETTINGS_ACCEPT_INVITE_START = 'ACCEPT_INVITE_START';
export const ACCOUNT_SETTINGS_ACCEPT_INVITE_SUCCESS = 'ACCEPT_INVITE_SUCCESS';
export const ACCOUNT_SETTINGS_ACCEPT_INVITE_ERROR = 'ACCEPT_INVITE_ERROR';

export const FETCH_ACTIVE_ORGANIZATIONS_START =
  'FETCH_ACTIVE_ORGANIZATIONS_START';
export const FETCH_ACTIVE_ORGANIZATIONS_SUCCESS =
  'FETCH_ACTIVE_ORGANIZATIONS_SUCCESS';
export const FETCH_ACTIVE_ORGANIZATIONS_ERROR =
  'FETCH_ACTIVE_ORGANIZATIONS_ERROR';

export const UPDATE_DEFAULT_ORG_START = 'UPDATE_DEFAULT_ORG_START';
export const UPDATE_DEFAULT_ORG_SUCCESS = 'UPDATE_DEFAULT_ORG_SUCCESS';
export const UPDATE_DEFAULT_ORG_ERROR = 'UPDATE_DEFAULT_ORG_ERROR';

export const LEAVE_ORG_START = 'LEAVE_ORG_START';
export const LEAVE_ORG_SUCCESS = 'LEAVE_ORG_SUCCESS';
export const LEAVE_ORG_ERROR = 'LEAVE_ORG_ERROR';
