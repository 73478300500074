export const ScatterForMatrixYAxisChoices = [
  {
    name: 'Col A',
    label: 'A',
  },
  {
    name: 'Col B',
    label: 'B',
  },
  {
    name: 'Col C',
    label: 'C',
  },
  {
    name: 'Col D',
    label: 'D',
  },
  {
    name: 'Col E',
    label: 'E',
  },
  {
    name: 'Col F',
    label: 'F',
  },
  {
    name: 'Col G',
    label: 'G',
  },
  {
    name: 'Col H',
    label: 'H',
  },
  {
    name: 'Col I',
    label: 'I',
  },
  {
    name: 'Col J',
    label: 'J',
  },
  {
    name: 'Col K',
    label: 'K',
  },
  {
    name: 'Col L',
    label: 'L',
  },
  {
    name: 'Col M',
    label: 'M',
  },
  {
    name: 'Col N',
    label: 'N',
  },
  {
    name: 'Col O',
    label: 'O',
  },
  {
    name: 'Col P',
    label: 'P',
  },
  {
    name: 'Col Q',
    label: 'Q',
  },
  {
    name: 'Col R',
    label: 'R',
  },
  {
    name: 'Col S',
    label: 'S',
  },
  {
    name: 'Col T',
    label: 'T',
  },
  {
    name: 'Col U',
    label: 'U',
  },
  {
    name: 'Col V',
    label: 'V',
  },
  {
    name: 'Col W',
    label: 'W',
  },
  {
    name: 'Col X',
    label: 'X',
  },
  {
    name: 'Col Y',
    label: 'Y',
  },
  {
    name: 'Col Z',
    label: 'Z',
  },
  {
    name: 'Col AA',
    label: 'AA',
  },
  {
    name: 'Col AB',
    label: 'AB',
  },
  {
    name: 'Col AC',
    label: 'AC',
  },
  {
    name: 'Col AD',
    label: 'AD',
  },
  {
    name: 'Col AE',
    label: 'AE',
  },
  {
    name: 'Col AF',
    label: 'AF',
  },
  {
    name: 'Col AG',
    label: 'AG',
  },
  {
    name: 'Col AH',
    label: 'AH',
  },
  {
    name: 'Col AI',
    label: 'AI',
  },
  {
    name: 'Col AJ',
    label: 'AJ',
  },
  {
    name: 'Col AK',
    label: 'AK',
  },
  {
    name: 'Col AL',
    label: 'AL',
  },
  {
    name: 'Col AM',
    label: 'AM',
  },
  {
    name: 'Col AN',
    label: 'AN',
  },
  {
    name: 'Col AO',
    label: 'AO',
  },
  {
    name: 'Col AP',
    label: 'AP',
  },
  {
    name: 'Col AQ',
    label: 'AQ',
  },
  {
    name: 'Col AR',
    label: 'AR',
  },
  {
    name: 'Col AS',
    label: 'AS',
  },
  {
    name: 'Col AT',
    label: 'AT',
  },
  {
    name: 'Col AU',
    label: 'AU',
  },
  {
    name: 'Col AV',
    label: 'AV',
  },
  {
    name: 'Col AW',
    label: 'AW',
  },
  {
    name: 'Col AX',
    label: 'AX',
  },
  {
    name: 'Col AY',
    label: 'AY',
  },
  {
    name: 'Col AZ',
    label: 'AZ',
  },
  {
    name: 'Col BA',
    label: 'BA',
  },
  {
    name: 'Col BB',
    label: 'BB',
  },
  {
    name: 'Col BC',
    label: 'BC',
  },
  {
    name: 'Col BD',
    label: 'BD',
  },
  {
    name: 'Col BE',
    label: 'BE',
  },
  {
    name: 'Col BF',
    label: 'BF',
  },
  {
    name: 'Col BG',
    label: 'BG',
  },
  {
    name: 'Col BH',
    label: 'BH',
  },
  {
    name: 'Col BI',
    label: 'BI',
  },
  {
    name: 'Col BJ',
    label: 'BJ',
  },
  {
    name: 'Col BK',
    label: 'BK',
  },
  {
    name: 'Col BL',
    label: 'BL',
  },
  {
    name: 'Col BM',
    label: 'BM',
  },
  {
    name: 'Col BN',
    label: 'BN',
  },
  {
    name: 'Col BO',
    label: 'BO',
  },
  {
    name: 'Col BP',
    label: 'BP',
  },
  {
    name: 'Col BQ',
    label: 'BQ',
  },
  {
    name: 'Col BR',
    label: 'BR',
  },
  {
    name: 'Col BS',
    label: 'BS',
  },
  {
    name: 'Col BT',
    label: 'BT',
  },
  {
    name: 'Col BU',
    label: 'BU',
  },
  {
    name: 'Col BV',
    label: 'BV',
  },
  {
    name: 'Col BW',
    label: 'BW',
  },
  {
    name: 'Col BX',
    label: 'BX',
  },
  {
    name: 'Col BY',
    label: 'BY',
  },
  {
    name: 'Col BZ',
    label: 'BZ',
  },
  {
    name: 'Col CA',
    label: 'CA',
  },
  {
    name: 'Col CB',
    label: 'CB',
  },
  {
    name: 'Col CC',
    label: 'CC',
  },
  {
    name: 'Col CD',
    label: 'CD',
  },
  {
    name: 'Col CE',
    label: 'CE',
  },
  {
    name: 'Col CF',
    label: 'CF',
  },
  {
    name: 'Col CG',
    label: 'CG',
  },
  {
    name: 'Col CH',
    label: 'CH',
  },
  {
    name: 'Col CI',
    label: 'CI',
  },
  {
    name: 'Col CJ',
    label: 'CJ',
  },
  {
    name: 'Col CK',
    label: 'CK',
  },
  {
    name: 'Col CL',
    label: 'CL',
  },
  {
    name: 'Col CM',
    label: 'CM',
  },
  {
    name: 'Col CN',
    label: 'CN',
  },
  {
    name: 'Col CO',
    label: 'CO',
  },
  {
    name: 'Col CP',
    label: 'CP',
  },
  {
    name: 'Col CQ',
    label: 'CQ',
  },
  {
    name: 'Col CR',
    label: 'CR',
  },
  {
    name: 'Col CS',
    label: 'CS',
  },
  {
    name: 'Col CT',
    label: 'CT',
  },
  {
    name: 'Col CU',
    label: 'CU',
  },
  {
    name: 'Col CV',
    label: 'CV',
  },
];

export const ScatterForMatrixXAxisChoices = [
  {
    name: 'Row',
    label: 'Row',
  },
  ...ScatterForMatrixYAxisChoices,
];
