import * as R from 'ramda';

import * as actionTypes from './MatrixActionsTypes';
import { RowNode } from 'ag-grid-community';

export const STATE_KEY = 'matrix';

const initialState = {
  matrix: {
    loading: false,
    data: [],
    error: false,
    filterModel: {},
    showCancel: false,
    selectedNodes: [],
    sortModel: [],
    lastRow: 0,
    orientingTable: {
      loading: false,
      data: [],
      error: false,
    },
    matrixModelDefinitions: [],
  },
};

export type MatrixStateType = {
  matrix: {
    loading: boolean;
    data: Record<string, any>[];
    error: boolean;
    filterModel: Record<string, any>;
    showCancel: boolean;
    selectedNodes: RowNode[];
    sortModel: Record<string, any>[];
    lastRow: number;
    orientingTable: {
      loading: boolean;
      data: Record<string, any>[];
      error: boolean;
    };
    matrixModelDefinitions: [string, string][];
  };
};

const MatrixReducer = (state = initialState, action: any) => {
  const { payload } = action;

  switch (action.type) {
    case actionTypes.FETCH_MATRIX_START: {
      return R.mergeDeepRight(state, { matrix: { loading: true } });
    }
    case actionTypes.FETCH_MATRIX_SUCCESS: {
      const shouldUpdateState = payload.shouldUpdateState;

      if (!shouldUpdateState) {
        return R.mergeDeepRight(state, {
          matrix: {
            loading: false,
          },
        });
      }

      return R.mergeDeepRight(state, {
        matrix: {
          data: R.propOr([], 'rows', payload),
          lastRow: R.propOr(0, 'lastRow', payload),
          loading: false,
        },
      });
    }
    case actionTypes.FETCH_MATRIX_ERROR: {
      return R.mergeDeepRight(state, { matrix: { error: payload } });
    }

    case actionTypes.SET_MATRIX_TABLE_FILTER: {
      return R.mergeRight(state, {
        matrix: { ...state.matrix, filterModel: payload },
      });
    }
    case actionTypes.SET_MATRIX_TABLE_SORT: {
      return R.mergeRight(state, {
        matrix: { ...state.matrix, sortModel: payload },
      });
    }
    case actionTypes.SET_MATRIX_SHOW_CANCEL: {
      return R.mergeDeepRight(state, {
        matrix: { showCancel: !state.matrix.showCancel },
      });
    }

    case actionTypes.SET_MATRIX_SELECTED_NODES: {
      return R.mergeDeepRight(state, {
        matrix: { selectedNodes: payload },
      });
    }
    case actionTypes.CLEAR_MATRIX_TABLE: {
      return initialState;
    }

    case actionTypes.FETCH_MATRIX_ORIENTING_TABLE_START: {
      return R.mergeDeepRight(state, {
        matrix: {
          orientingTable: { ...state.matrix.orientingTable, loading: true },
        },
      });
    }
    case actionTypes.FETCH_MATRIX_ORIENTING_TABLE_SUCCESS: {
      return R.mergeDeepRight(state, {
        matrix: {
          orientingTable: {
            data: payload,
            loading: false,
            error: false,
          },
        },
      });
    }
    case actionTypes.FETCH_MATRIX_ORIENTING_TABLE_ERROR: {
      return R.mergeDeepRight(state, {
        matrix: {
          orientingTable: { data: [], loading: false, error: payload },
        },
      });
    }

    case actionTypes.MATRIX_ADD_MODEL_DEFINITIONS: {
      if (state.matrix.matrixModelDefinitions.length > 0) {
        return state;
      }

      return R.mergeDeepRight(state, {
        matrix: {
          matrixModelDefinitions: payload,
        },
      });
    }

    default:
      return state;
  }
};

export default MatrixReducer;
