import { useAppSelector } from 'hooks/reduxHooks';

import { getModalEventData } from 'modules/GlobalSelectors';
import { lazy, Suspense } from 'react';
import { EventName } from 'utils/enum';

const Cancel = lazy(() => import('modules/cancel/Cancel'));
const Reactivate = lazy(() => import('modules/cancel/Reactivate'));
const DownloadOriginalFile = lazy(
  () => import('modules/originalFile/DownloadOriginalFile'),
);
const DownloadCurrentFile = lazy(
  () => import('modules/originalFile/DownloadCurrentFile'),
);
const PreviewFile = lazy(() => import('modules/originalFile/PreviewFile'));

const ModalEventContainer = () => {
  const { eventName } = useAppSelector(getModalEventData);

  switch (eventName) {
    case EventName.Inactivate: {
      return (
        <Suspense>
          <Cancel />;
        </Suspense>
      );
    }
    case EventName.Reactivate: {
      return (
        <Suspense>
          <Reactivate />;
        </Suspense>
      );
    }
    case EventName.DownloadOriginalFile: {
      return (
        <Suspense>
          <DownloadOriginalFile />;
        </Suspense>
      );
    }
    case EventName.DownloadCurrentFile: {
      return (
        <Suspense>
          <DownloadCurrentFile />;
        </Suspense>
      );
    }
    case EventName.PreviewFile: {
      return (
        <Suspense>
          <PreviewFile />;
        </Suspense>
      );
    }

    default:
      return null;
  }
};

export default ModalEventContainer;
