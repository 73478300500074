import * as actionTypes from './OrgSettingsActionTypes';

export type OrgSettingsUserType = {
  id: string;
  name: string;
  email: string;
  role_name: string;
};

export type OrgSettingsInvitationType = {
  id: string;
  invitee_name: string;
  invitee_email: string;
  date_invited: string;
};

type RoleType = {
  [key: string]: number;
};

export const STATE_KEY = 'organizationSettings';

const initialState = {
  activeUsers: {
    loading: false,
    error: false,
    message: '',
    users: <OrgSettingsUserType[]>[],
  },
  activeInvitations: {
    loading: false,
    error: false,
    message: '',
    invitations: <OrgSettingsInvitationType[]>[],
  },
  roles: {
    loading: false,
    error: false,
    message: '',
    roles: <RoleType>{},
  },
  createAndSendInvitation: {
    loading: false,
    error: false,
    message: '',
  },
  sendInviteEmail: {
    loading: false,
    error: false,
    message: '',
  },
  deleteInvite: {
    loading: false,
    error: false,
    message: '',
  },
  deleteUser: {
    loading: false,
    error: false,
    message: '',
  },
  updateUserRole: {
    loading: false,
    error: false,
    message: '',
  },
  updateOrgName: {
    loading: false,
    error: false,
    message: '',
  },
};

const OrganizationSettingsReducer = (state = initialState, action: any) => {
  const { payload } = action;

  switch (action.type) {
    case actionTypes.FETCH_ACTIVE_USERS_START: {
      return { ...state, activeUsers: { ...state.activeUsers, loading: true } };
    }
    case actionTypes.FETCH_ACTIVE_USERS_SUCCESS: {
      return {
        ...state,
        activeUsers: { error: false, loading: false, users: payload },
      };
    }
    case actionTypes.FETCH_ACTIVE_USERS_ERROR: {
      return {
        ...state,
        activeUsers: { error: payload, loading: false, users: [] },
      };
    }

    case actionTypes.FETCH_ACTIVE_INVITATIONS_START: {
      return {
        ...state,
        activeInvitations: { ...state.activeInvitations, loading: true },
      };
    }
    case actionTypes.FETCH_ACTIVE_INVITATIONS_SUCCESS: {
      return {
        ...state,
        activeInvitations: {
          loading: false,
          error: false,
          invitations: payload,
        },
      };
    }
    case actionTypes.FETCH_ACTIVE_INVITATIONS_ERROR: {
      return {
        ...state,
        activeInvitations: { loading: false, invitations: [], error: payload },
      };
    }

    case actionTypes.FETCH_ORG_ROLES_START: {
      return {
        ...state,
        roles: {
          ...state.roles,
          loading: true,
        },
      };
    }
    case actionTypes.FETCH_ORG_ROLES_SUCCESS: {
      return {
        ...state,
        roles: {
          roles: payload,
          loading: false,
          error: false,
        },
      };
    }
    case actionTypes.FETCH_ORG_ROLES_ERROR: {
      return {
        ...state,
        roles: {
          error: payload,
          loading: true,
          roles: {},
        },
      };
    }

    case actionTypes.CREATE_AND_SEND_INVITATION_START: {
      return {
        ...state,
        createAndSendInvitation: {
          ...state.createAndSendInvitation,
          loading: true,
        },
      };
    }
    case actionTypes.CREATE_AND_SEND_INVITATION_SUCCESS: {
      return {
        ...state,
        createAndSendInvitation: {
          loading: false,
          error: false,
          message: '',
        },
      };
    }
    case actionTypes.CREATE_AND_SEND_INVITATION_ERROR: {
      return {
        ...state,
        createAndSendInvitation: {
          loading: false,
          error: true,
          message: payload,
        },
      };
    }

    case actionTypes.SEND_INVITE_EMAIL_START: {
      return {
        ...state,
        sendInviteEmail: {
          ...state.sendInviteEmail,
          loading: true,
        },
      };
    }
    case actionTypes.SEND_INVITE_EMAIL_SUCCESS: {
      return {
        ...state,
        sendInviteEmail: {
          ...state.sendInviteEmail,
          loading: false,
          error: false,
          message: payload,
        },
      };
    }
    case actionTypes.SEND_INVITE_EMAIL_ERROR: {
      return {
        ...state,
        sendInviteEmail: {
          ...state.sendInviteEmail,
          loading: false,
          error: true,
          message: payload,
        },
      };
    }

    case actionTypes.DELETE_INVITATION_START: {
      return {
        ...state,
        deleteInvite: {
          ...state.deleteInvite,
          loading: true,
        },
      };
    }
    case actionTypes.DELETE_INVITATION_SUCCESS: {
      return {
        ...state,
        deleteInvite: {
          ...state.deleteInvite,
          loading: false,
          error: false,
          message: payload,
        },
      };
    }
    case actionTypes.DELETE_INVITATION_ERROR: {
      return {
        ...state,
        deleteInvite: {
          ...state.deleteInvite,
          loading: false,
          error: true,
          message: payload,
        },
      };
    }

    case actionTypes.DELETE_USER_START: {
      return {
        ...state,
        deleteUser: {
          ...state.deleteUser,
          loading: true,
        },
      };
    }
    case actionTypes.DELETE_USER_SUCCESS: {
      return {
        ...state,
        deleteUser: {
          ...state.deleteUser,
          loading: false,
          error: false,
          message: payload,
        },
      };
    }
    case actionTypes.DELETE_USER_ERROR: {
      return {
        ...state,
        deleteUser: {
          ...state.deleteUser,
          loading: false,
          error: true,
          message: payload,
        },
      };
    }

    case actionTypes.UPDATE_USER_ROLE_START: {
      return {
        ...state,
        updateUserRole: {
          ...state.updateUserRole,
          loading: true,
        },
      };
    }
    case actionTypes.UPDATE_USER_ROLE_SUCCESS: {
      return {
        ...state,
        updateUserRole: {
          ...state.updateUserRole,
          loading: false,
          error: false,
          message: payload,
        },
      };
    }
    case actionTypes.UPDATE_USER_ROLE_ERROR: {
      return {
        ...state,
        updateUserRole: {
          ...state.updateUserRole,
          loading: false,
          error: true,
          message: payload,
        },
      };
    }

    case actionTypes.UPDATE_ORG_NAME_START: {
      return {
        ...state,
        updateOrgName: {
          ...state.updateUserRole,
          loading: true,
        },
      };
    }
    case actionTypes.UPDATE_ORG_NAME_SUCCESS: {
      return {
        ...state,
        updateOrgName: {
          loading: false,
          error: false,
          message: payload,
        },
      };
    }
    case actionTypes.UPDATE_ORG_NAME_ERROR: {
      return {
        ...state,
        updateOrgName: {
          loading: false,
          error: true,
          message: payload,
        },
      };
    }
    default:
      return state;
  }
};

export default OrganizationSettingsReducer;
