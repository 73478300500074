import * as actionTypes from './AccountSettingsActionTypes';
export const STATE_KEY = 'accountSettings';

export type ActiveIncomingInvitationType = {
  id: string;
  invitee_name: string;
  invitee_email: string;
  date_invited: string;
};

export type ActiveOrganizationType = {
  id: string;
  organization_name: string;
  is_default: boolean;
  role_name: string;
};

const initialState = {
  activeIncomingInvitations: {
    loading: false,
    error: false,
    message: '',
    invitations: <ActiveIncomingInvitationType[]>[],
  },
  acceptInvite: {
    loading: false,
    error: false,
    message: '',
  },
  activeOrganizations: {
    loading: false,
    error: false,
    message: '',
    organizations: <ActiveOrganizationType[]>[],
  },
  updateDefaultOrg: {
    loading: false,
    error: false,
    message: '',
  },
  leaveOrganization: {
    loading: false,
    error: false,
    message: '',
  },
};

const AccountSettingsReducer = (state = initialState, action: any) => {
  const { payload } = action;

  switch (action.type) {
    case actionTypes.FETCH_ACTIVE_INCOMING_INVITATIONS_START: {
      return {
        ...state,
        activeIncomingInvitations: {
          ...state.activeIncomingInvitations,
          loading: true,
        },
      };
    }
    case actionTypes.FETCH_ACTIVE_INCOMING_INVITATIONS_SUCCESS: {
      return {
        ...state,
        activeIncomingInvitations: {
          message: '',
          error: false,
          loading: false,
          invitations: payload,
        },
      };
    }
    case actionTypes.FETCH_ACTIVE_INCOMING_INVITATIONS_ERROR: {
      return {
        ...state,
        activeIncomingInvitations: {
          error: true,
          loading: false,
          message: payload,
          invitations: [],
        },
      };
    }
    case actionTypes.ACCOUNT_SETTINGS_ACCEPT_INVITE_START: {
      return {
        ...state,
        acceptInvite: {
          error: false,
          message: '',
          loading: true,
        },
      };
    }
    case actionTypes.ACCOUNT_SETTINGS_ACCEPT_INVITE_SUCCESS: {
      return {
        ...state,
        acceptInvite: {
          message: payload,
          error: false,
          loading: false,
        },
      };
    }
    case actionTypes.ACCOUNT_SETTINGS_ACCEPT_INVITE_ERROR: {
      return {
        ...state,
        acceptInvite: {
          message: payload,
          loading: false,
          error: true,
        },
      };
    }
    case actionTypes.FETCH_ACTIVE_ORGANIZATIONS_START: {
      return {
        ...state,
        activeOrganizations: {
          ...state.activeOrganizations,
          loading: true,
        },
      };
    }
    case actionTypes.FETCH_ACTIVE_ORGANIZATIONS_SUCCESS: {
      return {
        ...state,
        activeOrganizations: {
          error: false,
          message: '',
          loading: false,
          organizations: payload,
        },
      };
    }
    case actionTypes.FETCH_ACTIVE_ORGANIZATIONS_ERROR: {
      return {
        ...state,
        activeOrganizations: {
          organizations: [],
          loading: false,
          error: true,
          message: payload,
        },
      };
    }
    case actionTypes.UPDATE_DEFAULT_ORG_START: {
      return {
        ...state,
        updateDefaultOrg: {
          ...state.updateDefaultOrg,
          loading: true,
        },
      };
    }
    case actionTypes.UPDATE_DEFAULT_ORG_SUCCESS: {
      return {
        ...state,
        updateDefaultOrg: {
          error: false,
          loading: false,
          message: payload,
        },
      };
    }
    case actionTypes.UPDATE_DEFAULT_ORG_ERROR: {
      return {
        ...state,
        updateDefaultOrg: {
          loading: false,
          error: true,
          message: payload,
        },
      };
    }
    case actionTypes.LEAVE_ORG_START: {
      return {
        ...state,
        leaveOrganization: {
          ...state.leaveOrganization,
          loading: true,
        },
      };
    }
    case actionTypes.LEAVE_ORG_SUCCESS: {
      return {
        ...state,
        leaveOrganization: {
          error: false,
          loading: false,
          message: payload,
        },
      };
    }
    case actionTypes.LEAVE_ORG_ERROR: {
      return {
        ...state,
        leaveOrganization: {
          loading: false,
          error: true,
          message: payload,
        },
      };
    }
    default:
      return state;
  }
};

export default AccountSettingsReducer;
