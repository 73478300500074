import { clearOrgNamespace, setOrgNamespace } from 'helpers/localeStorage';
import * as actionTypes from 'modules/GlobalActionsTypes';

export const STATE_KEY = 'organization';

export type Organization = { name_display: string; namespace: 'string' };

export type OrganizationDataType = {
  data: Array<Organization>;
  loading: boolean;
  activeOrganization: Organization | null;
  error: string;
};

const initialState = {
  loading: false,
  data: [],
  selectedOrganization: null,
  activeOrganization: null,
  error: false,
};

const SelectOrganizationReducer = (state = initialState, action: any) => {
  const { payload } = action;

  switch (action.type) {
    case actionTypes.FETCH_USER_ORGANIZATIONS_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case actionTypes.FETCH_USER_ORGANIZATIONS_SUCCESS: {
      setOrgNamespace(payload?.activeOrganization?.namespace);
      return {
        ...state,
        loading: false,
        data: payload.data,
        selectedOrganization: payload.selectedOrganization,
        activeOrganization: payload.activeOrganization,
      };
    }
    case actionTypes.FETCH_USER_ORGANIZATIONS_ERROR: {
      return {
        ...state,
        loading: false,
        selectedOrganization: null,
        activeOrganization: null,
        error: payload,
      };
    }
    case actionTypes.UPDATE_USER_SELECTED_ORGANIZATION: {
      return {
        ...state,
        selectedOrganization: payload,
      };
    }
    case actionTypes.UPDATE_USER_ACTIVE_ORGANIZATION: {
      setOrgNamespace(payload.namespace);
      return {
        ...state,
        activeOrganization: payload,
      };
    }
    case actionTypes.UPDATE_USER_ORGANIZATION: {
      setOrgNamespace(payload.namespace);
      return {
        ...state,
        activeOrganization: payload,
        selectedOrganization: payload,
      };
    }
    case actionTypes.CLEAR_USER_ORGANIZATION: {
      clearOrgNamespace();
      return initialState;
    }

    default:
      return state;
  }
};

export default SelectOrganizationReducer;
