import { Route, Routes } from 'react-router-dom';

import Preloader from 'components/Preloader/Preloader';
import { lazy, Suspense } from 'react';

const SignUpPage = lazy(() => import('./modules/auth/pages/SignUpPage'));

const SignUpInvitedPage = lazy(
  () => import('./modules/auth/pages/SignUpPageInvited'),
);

const LoginPage = lazy(() => import('./modules/auth/pages/LoginPage'));

const UpdatePasswordPage = lazy(
  () => import('./modules/auth/pages/UpdatePasswordPage'),
);
const ForgotPasswordPage = lazy(
  () => import('./modules/auth/pages/ForgotPasswordPage'),
);
const PasswordUpdateSuccessPage = lazy(
  () => import('./modules/auth/pages/PasswordUpdateSuccessPage'),
);
const PasswordResetSentPage = lazy(
  () => import('./modules/auth/pages/PasswordResetSentPage'),
);

const InvitePage = lazy(() => import('./modules/auth/pages/InvitePage'));

export default (
  <Routes>
    <Route
      path="/auth/sign-up"
      element={
        <Suspense fallback={<Preloader />}>
          <SignUpPage />
        </Suspense>
      }
    />
    <Route
      path="/auth/sign-up-invited"
      element={
        <Suspense fallback={<Preloader />}>
          <SignUpInvitedPage />
        </Suspense>
      }
    />
    <Route
      path="/auth/login"
      element={
        <Suspense fallback={<Preloader />}>
          <LoginPage />
        </Suspense>
      }
    />
    <Route
      path="/auth/confirmforgot"
      element={
        <Suspense fallback={<Preloader />}>
          <UpdatePasswordPage />
        </Suspense>
      }
    />
    <Route
      path="/auth/forgot-password"
      element={
        <Suspense fallback={<Preloader />}>
          <ForgotPasswordPage />
        </Suspense>
      }
    />
    <Route
      path="/auth/password-update-success"
      element={
        <Suspense fallback={<Preloader />}>
          <PasswordUpdateSuccessPage />
        </Suspense>
      }
    />
    <Route
      path="/auth/password-reset-sent"
      element={
        <Suspense fallback={<Preloader />}>
          <PasswordResetSentPage />
        </Suspense>
      }
    />
    <Route
      path="/invite"
      element={
        <Suspense fallback={<Preloader />}>
          <InvitePage />
        </Suspense>
      }
    />
  </Routes>
);
